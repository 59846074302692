:root {
  --text-color: #dadce1;
  --nav-size: 10vh;
  --border-radius: 2%;
  --speed: 500ms; 
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.text-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: .7vmax;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.text-button:hover {
  filter: brightness(1.2);
}

.posdiv{
  position: absolute;
  top: 01vh;
  text-align: center;
  width: 12vw;
  transform: translateX(-70%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1vmax;
  overflow: hidden;
  transition: height var(--speed) ease;
  background-color: #28292b;
}

.posdiv li{
  width: 10vw;
  margin: auto;
  padding: auto;
  border-radius: 3%;
  height: 5vh;
  margin-bottom: 1vh;
  line-height: 4vh;
  vertical-align: center;
  font-size: 1.5vmax;
}

.posdiv li:hover {
  filter: brightness(1.2);
  background-color: #545658;
  transition: 500ms;
}

.sticky{
  position: fixed;
  right:2vw;
  top:1vh
}

