*html {
  position: relative;
}
* h1,
p,
h2,
h3 {
  color: white;
  font-family: sans-serif;
}

* p a {
  color: #eef6ebda;
  font-family: sans-serif;
}

@media all and (min-width:0px) and (max-width: 320px) {
}
.TitleImage {
  height: 20vw;
  width: 20vw;
}

.Intro p{
    color:#141414;
    font-size: 2vmax;
}

.Intro h1{
    color:#141414;
    font-size: 4vmax;
}
.Intro {
  top:0;
  position:relative;  
  display: block;
  height: 105vh;
  width: 100vw;
  background: #f1f1f1;
  color: black;
}

.IntroDiv {
  padding-top: 35vh;
  text-align: center;
}

.IntroDivChild {
  display: inline-block;
  vertical-align: middle;
  width:50vw;
}

.about {
  background: #141414;
  position:relative;
  height: 120vh;
  width: 100vw;
  display: block;
}

.about p {
  color: #808d9c;
  font-size: 1vmin;
  font-size: 1.2vmax;
  margin-bottom: 0;
  margin-left: 0.2vw;
}

.about h1 {
  margin-top: 0vh;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 2vmin;
  font-size: 3vmax;
}

.aboutNumberDiv {
  margin-top: 8vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.aboutWrapper {
  margin-left: 5vw;
  width: 90vw;
  margin-right: 5vw;
  padding-top: 25vh;
}
.aboutNumberDivChild {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0vw;
  margin-right: 9vw;
  width: 25vw;
}

.aboutNumberDivChild p {
  margin: 0;
  font-size: 3vh;
  color: white;
  text-align: left;
  font-size: 1.3vmax;
  margin-top: 2.5vh;
}

.aboutNumberDivChild h1 {
  color: #808d9c;
  text-align: left;
  font-size: 8vmax;
  margin: 0;
}

.aboutNumberDivChild h2 {
  text-align: left;
  display: inline;
  margin: 0;
  font-size: 1.5vmax;
}

.projects {
  position:relative;

  background: #ffffff;
  width: 100vw;
  height: 200vh;
  display: block;
}

.projectswrapper {
  padding-top: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.projects img {
  height: 20vw;
  width: 20vw;
}

.projects p {
  color: #45484c;
  font-size: 1vmin;
  font-size: 1.2vmax;
  margin-bottom: 0;
  margin-left: 0.2vw;
}

.projects h1 {
  margin-top: 0vh;
  margin-bottom: 0;
  color: #5b4b4beb;
  font-size: 2vmin;
  font-size: 3vmax;
}

.projectsTitle {
  width: 40vw;
  height: 20vh;
  float: left;
  padding-bottom: 8vh;
}

.projectOutline {
  height: 60vh;
  width: 44vw;
  background-color: #9b999996;
  text-align: center;
  margin-bottom: 2.2vw;
  border-radius: 2%;
}

.projectVertical img {
  height: 60vh;
  width: 20vw;
  display: inline-block;
  vertical-align: top;
  border-radius: 2%;
}

.projectVertical p {
  color: #2e2e2e;
  font-size: 1vmin;
  font-size: 1.3vmax;
  margin-bottom: 0;
  margin-left: 0.2vw;
}
.readMoreButton {
  border: 0.1vw solid #ff7675;
  height: 5vh;
  width: 0vw;
}

.readMoreButton a{
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    font-size: 1.1vmax;
    line-height: 5vh;
    color: #ff7675;
}

.readMoreButton:hover a {
    color: white;
}


.readMoreButton:hover {
  background-color: rgb(255, 118, 117);
  /* box-shadow: 0 1vh rgb(105, 231, 248); */
  box-shadow: 0 0.6vw 1.3vw 0 rgba(252, 150, 150, 0.918),
    0 0.5vw 1.4vw 0 rgba(252, 150, 150, 0.829);
  transform: translateY(-0.5vw);
  scale: 1.05;
  transition: 300ms;
}

.btn {
  display: block;
  width:20vw;
  height:8vh;
  position: relative;
  font-family: "montserrat";
  text-decoration: none;
  border: 2px solid #ff7675;
  overflow: hidden;
  transition: 1s  ease;
}
.btn::before {
  background: #ff7675;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition:  0.6s ease;
}

.btn3::before {
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}
.btn3:hover::before {
  height: 380%;
}
.middle {
  top: 100%;
  margin-top: 8vh;
  left: 100%;
  float:right;
  /* margin-right: 5vw; */
  transform: translate(-50%, -50%);
  vertical-align: middle;
  text-align: center;
  
}

.middle div{
  line-height: 8vh;
    margin:auto;
}
.middle a{
    font-size: 1.3vmax;
    color: #ff7675;
    margin: auto;
}
.middle:hover a{
    color: white;
}
.moreProjectsButton a {
  color: white;
  font-size: 2vmax;
}

.projectVertical h1 {
  margin-top: 0vh;
  margin-bottom: 0;
  color: black;
  font-size: 2vmin;
  font-size: 2vmax;
}
.projectVertical div {
  display: inline-block;
  vertical-align: top;
  width: 20vw;
  margin: 2vw;
}

.projectHorizontal img {
    height:25vh;
    width:44vw;
}

.projectHorizontal p {
    color: #2e2e2e;
    font-size: 1.3vmax;
    margin-bottom: 0;
    margin-left: 0.2vw;
}

.projectHorizontal h1 {
    color: black;
}
.projectHorizontal div {
    width:40vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
}

.career{
  position: relative;
    background: #141414;
    width: 100vw;
    display: block;
}

.timeline{
    width: 80vw;
    background:#141414;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    display: grid;
    color:white;
    grid-template-columns: .3vw 1fr 2.5fr;
}

.timline__components{
    margin: 0vw 2vw 6vw 2vw;
    
}

.timline__components p{
  font-size: 1.3vmax;
}

.timline__components h1{
  font-size: 2vmax;
}

.timline__components h2{
  font-size: 2vmax;
}

.timeline__right{
    text-align: right;
}

.timeline__middle{
    position: relative; 
    background-color: #5e21e4;
}

.timeline_point{
    margin-top: 0vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2vw;
    height: 2vw;
    background-color: #5e21e4;
    border-radius: 50%;
}
.timeline__date{
  left:0;
  width: auto;
  font-size: 1.5vmax;

}

.timeline__component--bg{
    padding: 2vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    background-color: #5e21e4;
    color: black;
    box-shadow: 0, 0, .1vw rgba(0,0,0,0.2);
    border-radius: 2%;
    text-align: left;
    margin-left: 0;
}

.careerTitle p {
    color: #808d9c;
    font-size: 1.2vmax;
    margin-bottom: 0;
    margin-left: 0.2vw;

  }
  
.careerTitle h1 {
    margin-top: 0vh;
    margin-bottom: 10vh;
    color: #ffffff;
    font-size: 2.2vmax;
}

.careerTitle{
    width: 55vw;
    margin-left: 5vw;
    padding-top: 15vh;
    margin-bottom: 10vh;
}

.weird{
  height: 10vh;
  width: 100%;
  background-color: #ff7675;
}